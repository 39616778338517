import Rest from '@/helpers/Rest'

const rest = new Rest('sensor_models')

const index = (context, payload) => {
  return rest.index(context, payload)
}

const store = (context, payload) => {
  return rest.store(context, payload)
}

const update = (context, payload) => {
  return rest.update(context, payload)
}

const destroy = (context, payload) => {
  return rest.destroy(context, payload)
}

export default {
  index,
  store,
  update,
  destroy
}
