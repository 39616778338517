import Rest from '@/helpers/Rest'
import axios from 'axios'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
window.Pusher = Pusher

const rest = new Rest('devices')

const index = (context, payload) => {
  return rest.index(context, payload)
}

const store = (context, payload) => {
  return rest.store(context, payload)
}

const update = (context, payload) => {
  return rest.update(context, payload)
}

const destroy = (context, payload) => {
  return rest.destroy(context, payload)
}

const data = (context, payload) => {
  const params = payload.params || null
  return new Promise((resolve, reject) => {
    axios.get('/api/devices/' + payload.id + '/data/', params)
      .then((response) => {
        resolve(response.data)
      })
      .catch(e => reject(e))
  })
}

const xlsData = (context, payload) => {
  const params = payload.params || null

  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: '/api/devices/' + payload.id + '/data/xls/',
      params: params.params,
      responseType: 'blob'
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'report.xlsx')
        document.body.appendChild(link)
        link.click()
        resolve()
      })
      .catch(e => reject(e))
  })
}

const setLastData = (context, payload) => {
  context.commit('SET_LAST_DATA', payload)
}

const subscribe = (context) => {
  const echo = new Echo({
    broadcaster: 'pusher',
    authEndpoint: 'http://iotc.lc/api/broadcasting/auth',
    key: process.env.VUE_APP_PUSHER_KEY,
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    forceTLS: true,
    authorizer: (channel, options) => {
      return {
        authorize: (socketId, callback) => {
          axios.post('/api/broadcasting/auth', {
            socket_id: socketId,
            channel_name: channel.name
          }).then(response => {
            callback(false, response.data)
          }).catch(error => {
            callback(true, error)
          })
        }
      }
    }
  })
  index().then((response) => {
    response.map((device) => {
      echo.private('device.data.update.' + device.id).listen('ModuleDataInsert', e => {
        console.log(e)
        context.commit('SET_LAST_DATA', {
          deviceId: e.device,
          data: e.data
        })
        context.commit('SET_DATA_UPDATED')
      })
    })
  })
}

export default {
  index,
  store,
  update,
  destroy,
  data,
  xlsData,
  setLastData,
  subscribe
}
