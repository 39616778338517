import axios from 'axios'

export default class Rest {
  constructor (url) {
    this.baseUrl = '/api/' + url + '/'
  }

  index (context, payload) {
    const params = payload || null
    return new Promise((resolve, reject) => {
      axios.get(this.baseUrl, params)
        .then((response) => {
          resolve(response.data)
        })
        .catch(e => reject(e))
    })
  }

  store (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(this.baseUrl, payload.params)
        .then((response) => {
          resolve(response.data)
        })
        .catch(e => reject(e))
    })
  }

  update (context, payload) {
    return new Promise((resolve, reject) => {
      axios.put(this.baseUrl + payload.params.id, payload.params)
        .then((response) => {
          for (const key in payload.item) {
            payload.item[key] = response.data[key]
          }
          resolve(response.data)
        })
        .catch(e => reject(e))
    })
  }

  destroy (context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(this.baseUrl + payload.item.id)
        .then((response) => {
          resolve(response.data)
        })
        .catch(e => reject(e))
    })
  }
}
