import Vue from 'vue'
import Vuex from 'vuex'

import App from './modules/app/index'
import Auth from './modules/auth/index'
import Devices from './modules/devices/index'
import DeviceGroups from './modules/deviceGroups/index'
import Gis from './modules/gis/index'
import Normalizators from './modules/normalizators/index'
import Parameters from './modules/parameters/index'
import PlotPresets from './modules/plotPresets/index'
import SensorModels from './modules/sensorModels/index'
import Units from './modules/units/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app: App,
    auth: Auth,
    devices: Devices,
    deviceGroups: DeviceGroups,
    gis: Gis,
    normalizators: Normalizators,
    parameters: Parameters,
    plotPresets: PlotPresets,
    sensorModels: SensorModels,
    units: Units
  }
})
