<template>
  <transition name="slide">
    <v-alert
      v-if="message"
      class="system-message"
      :color="message.color || 'primary'"
      :icon="message.icon || 'mdi-info'"
      border="left"
    >
      {{ message.message }}
    </v-alert>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      message: 'app/systemMessage'
    })
  }
}
</script>

<style scoped>
.system-message {
  position: fixed !important;
  top: 0px;
  right: 0;
  width: 300px;
  margin: 30px;
  z-index: 20000;
}
.slide-enter-active {
  transition: all .3s ease;
}
.slide-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-enter, .slide-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(500px);
}
</style>
