import Map from 'ol/Map.js'
import View from 'ol/View.js'
import { Vector as VectorSource, Cluster } from 'ol/source.js'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js'
import { fromLonLat } from 'ol/proj'
import { Select } from 'ol/interaction.js'
import { never, singleClick } from 'ol/events/condition.js'
import { Style, Stroke, Fill, Circle as CircleStyle, Text } from 'ol/style.js'

import Feature from 'ol/Feature'
import { Point } from 'ol/geom.js'

import TileJSON from 'ol/source/TileJSON'

import { clusterStyle } from './styles'

const setMap = (context) => {
  context.dispatch('setClusterSource')
  context.dispatch('setSource')
  context.dispatch('setTileLayer')
  context.dispatch('setSelect')
  context.dispatch('getUserPosition')

  const source = context.getters.source
  const clusterSource = context.getters.clusterSource
  const select = context.getters.select

  const cluster = new Cluster({
    distance: 20,
    source: clusterSource
  })

  const vectorLayer = new VectorLayer({
    source: source,
    zIndex: 2
  })

  const clusterLayer = new VectorLayer({
    source: cluster,
    style: clusterStyle,
    zIndex: 3
  })

  const map = new Map({
    target: 'gisMap',

    controls: [],

    layers: [
      clusterLayer,
      vectorLayer
    ],

    view: new View({
      center: fromLonLat([40.15, 43.83]),
      zoom: 8
    })
  })

  map.addInteraction(select)
  select.on('select', (e) => {
    if (!e.selected.length) { clusterSource.changed() }
  })

  context.commit('SET_MAP', map)
}

const setTileLayer = (context) => {
  const tileSource = new TileJSON({
    url: 'https://api.maptiler.com/maps/562caef0-99b4-4a43-b858-42f58ee198cd/tiles.json?key=N5kSCZ8WtSKjMtOjPM74',
    tileSize: 512,
    crossOrigin: 'anonymous'
  })

  const tileLayer = new TileLayer({
    source: tileSource,
    zIndex: 1
  })

  context.commit('SET_TILE_LAYER', tileLayer)
}

const setClusterSource = (context) => {
  const source = new VectorSource({
    wrapX: false
  })
  context.commit('SET_CLUSTER_SOURCE', source)
}

const setSource = (context) => {
  const source = new VectorSource({
    wrapX: false
  })
  context.commit('SET_SOURCE', source)
}

const setSelect = (context) => {
  const select = new Select({
    condition: singleClick,
    toggleCondition: never,
    style: clusterStyle,
    multi: false,
    hitTolerance: 2,
    filter: (feature) => {
      return feature.get('selectable') !== false
    }
  })
  context.commit('SET_SELECT', select)
}

const loadDevices = (context) => {
  return new Promise((resolve, reject) => {
    context.dispatch('devices/index', {}, { root: true })
      .then(response => {
        const devices = response
        const map = context.getters.map
        const source = context.getters.clusterSource
        const tileLayer = context.getters.tileLayer

        const features = devices.map((device) => {
          const feature = new Feature({
            geometry: new Point(fromLonLat(device.location.coordinates))
          })
          feature.set('selectable', true)
          feature.set('class', 'Device')
          feature.set('model', device)
          return feature
        })

        context.commit('SET_DEVICES', features)
        source.addFeatures(features)

        map.getView().fit(source.getExtent(), {
          padding: [50, 50, 50, 50],
          maxZoom: 17
        })

        map.addLayer(tileLayer)
        resolve(true)
      }).catch(e => { reject(e) })
  })
}

const getUserPosition = (context) => {
  let userPosition = null
  const source = context.getters.source
  setInterval(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        if (userPosition && source.hasFeature(userPosition)) {
          source.removeFeature(userPosition)
        }
        userPosition = new Feature({
          geometry: new Point(fromLonLat([location.coords.longitude, location.coords.latitude]))
        })
        userPosition.set('selectable', false)
        userPosition.setStyle(() => {
          return [
            new Style({
              image: new CircleStyle({
                stroke: new Stroke({
                  color: 'white',
                  width: 2
                }),
                fill: new Fill({
                  color: '#0288D1'
                }),
                radius: 10
              })
            }),
            new Style({
              text: new Text({
                text: 'Я',
                font: 'bold 10px sans-serif',
                fill: new Fill({
                  color: 'white'
                }),
                zIndex: 210
              })
            })
          ]
        })
        source.addFeature(userPosition)
      })
    }
  }, 2000)
}

const unsetMap = (context) => {
  context.getters.source.clear()
  context.getters.clusterSource.clear()
  context.commit('SET_MAP', null)
  context.commit('SET_TILE_LAYER', null)
  context.commit('SET_CLUSTER_SOURCE', null)
  context.commit('SET_SOURCE', null)
  context.commit('SET_DEVICES', null)
  context.commit('SET_SELECT', null)
}

const searchDevice = (context, pattern) => {
  if (!pattern.length) return []
  return context.getters.devices.filter(dev => {
    return dev.get('model').title.toLowerCase().includes(pattern.toLowerCase())
  })
}

const selectInCluster = (context, feature) => {
  const select = context.getters.select
  const cs = context.getters.clusterSource
  const map = context.getters.map
  const container = new Feature(feature.getGeometry())

  select.getFeatures().clear()
  container.set('features', [feature])
  select.getFeatures().push(container)
  cs.changed()
  map.getView().fit(feature.getGeometry(), {
    padding: [15, 15, 15, 15],
    maxZoom: 17,
    duration: 300
  })
}

const setActiveDevice = (context, device) => {
  context.commit('SET_ACTIVE_DEVICE', device)
}

export default {
  setActiveDevice,
  setMap,
  setClusterSource,
  setSource,
  setTileLayer,
  setSelect,
  loadDevices,
  getUserPosition,
  unsetMap,
  searchDevice,
  selectInCluster
}
