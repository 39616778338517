import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  map: null,
  source: null,
  tileLayer: null,
  select: null,
  devices: [],
  activeDevice: null
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
