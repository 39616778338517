const setSystemMessage = (context, payload) => {
  context.commit('SET_SYSTEM_MESSAGE', payload)
  setInterval(() => {
    context.commit('SET_SYSTEM_MESSAGE', null)
  }, 3500)
}

export default {
  setSystemMessage
}
