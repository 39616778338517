const map = state => state.map
const source = state => state.source
const clusterSource = state => state.clusterSource
const tileLayer = state => state.tileLayer
const activeDevice = state => state.activeDevice
const select = state => state.select
const selected = state => state.select ? state.select.getFeatures().getArray() : null
const hasSelection = state => state.select ? state.select.getFeatures().getLength() > 0 : false

const devices = state => state.devices

export default {
  activeDevice,
  devices,
  map,
  source,
  clusterSource,
  tileLayer,
  select,
  selected,
  hasSelection
}
