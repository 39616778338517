<template>
  <v-navigation-drawer
    v-model="drawer_"
    color="blue-grey darken-4"
    width="300"
    :height="navHeight"
    :mini-variant.sync="mini"
    mobile-breakpoint="400"
    app
    @input="$emit('input', drawer_)"
  >
    <v-list-item class="px-2">
      <v-list-item-icon>
        <img height="50" class="pt-1" src="images/iotc_logo.png" />
      </v-list-item-icon>

      <v-list-item-title class="ml-n4 pt-1">
        <img height="50" class="pt-2" src="images/iotc_sign.png" />
      </v-list-item-title>
      <v-btn icon @click="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <div v-for="(block, i) in items" :key="i">
      <v-list dense shaped>
        <v-list-item
          v-for="item in block"
          :key="item.title"
          :to="item.route"
          class="mb-2"
          link
        >
          <v-list-item-icon>
            <v-icon :small="!mini">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider class="mb-2" />
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    drawer: Boolean
  },

  data () {
    return {
      drawer_: true,
      items: [
        [
          { title: 'Рабочий стол', icon: 'mdi-view-dashboard', route: '/' },
          { title: 'Карта', icon: 'mdi-map-marker', route: 'gis' },
          { title: 'Устройства', icon: 'mdi-access-point', route: 'devices' }
        ], [
          { title: 'Настройки', icon: 'mdi-cog-outline', route: 'settings' }
        ]
      ],
      mini: true
    }
  },

  watch: {
    drawer (val) {
      this.drawer_ = val
    }
  },

  computed: {
    appBarHeight () {
      return this.$vuetify.breakpoint.smAndDown ? 56 : 64
    },

    navHeight () {
      return window.innerHeight
    }
  }
}
</script>

<style>

</style>
