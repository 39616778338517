import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      hideNav: true,
      unregisterd: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isAuthorized']) {
        next('/')
      } else {
        store.dispatch('auth/getUser').then(() => {
          if (store.getters['auth/isAuthorized']) {
            next('/')
          } else {
            next()
          }
        }).catch(() => {
          next()
        })
      }
    }
  },
  {
    path: '/gis',
    name: 'Gis',
    component: () => import('@/views/Gis/Index.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/Settings/Index.vue')
  },
  {
    path: '/devices',
    name: 'Devices',
    component: () => import('@/views/Devices/Index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => !record.meta.unregisterd)) {
    let isAuthorized = store.getters['auth/isAuthorized']
    if (!isAuthorized) {
      store.dispatch('auth/getUser').then(() => {
        isAuthorized = store.getters['auth/isAuthorized']

        if (!isAuthorized) {
          next('/login')
        } else {
          // const user = store.getters['auth/user']
          // ability.update(defineAbilityFor(user).rules)
          // if (to.meta.requiresRights && !ability.can('read', to.meta.requiresRights)) {
          //   next('/403')
          // }
          next()
        }
      }).catch(() => {
        next('/login')
      })
    } else {
      // const user = store.getters['auth/user']
      // ability.update(defineAbilityFor(user).rules)
      // if (to.meta.requiresRights && !ability.can('read', to.meta.requiresRights)) {
      //   next('/403')
      // }
      next()
    }
  } else {
    next()
  }
})

export default router
