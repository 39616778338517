const SET_LAST_DATA = (state, payload) => {
  state.lastData[payload.deviceId] = payload.data
}

const SET_DATA_UPDATED = (state) => {
  state.dataUpdated = new Date().getTime()
}

export default {
  SET_LAST_DATA,
  SET_DATA_UPDATED
}
