const SET_MAP = (state, map) => {
  state.map = map
}

const SET_SOURCE = (state, source) => {
  state.source = source
}

const SET_CLUSTER_SOURCE = (state, source) => {
  state.clusterSource = source
}

const SET_SELECT = (state, select) => {
  state.select = select
}

const SET_TILE_LAYER = (state, layer) => {
  state.tileLayer = layer
}

const SET_DEVICES = (state, devices) => {
  state.devices = devices
}

const SET_ACTIVE_DEVICE = (state, device) => {
  state.activeDevice = device
}

export default {
  SET_ACTIVE_DEVICE,
  SET_DEVICES,
  SET_MAP,
  SET_SOURCE,
  SET_CLUSTER_SOURCE,
  SET_TILE_LAYER,
  SET_SELECT
}
