<template>
  <v-app id="inspire" v-if="$route.matched.length">
    <navigation
      v-if="!hideNav"
      :drawer="drawer"
      @input="setDrawer"
    />
    <v-btn
      elevation="2"
      fab
      small
      v-if="!hideNav && !drawer && $vuetify.breakpoint.smAndDown"
      style="position: fixed; z-index: 10000; margin: 10px; opacity: 0.8"
      @click="drawer = !drawer"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <v-btn
      elevation="2"
      fab
      small
      color="transparent"
      v-if="!hideNav && drawer && $vuetify.breakpoint.smAndDown"
      style="position: fixed; z-index: 10000; bottom:0; margin: 7px; opacity: 0.8"
      @click="drawer = !drawer"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <!-- <v-app-bar
      app
      color="blue-grey darken-4"
      v-if="!hideNav && $vuetify.breakpoint.smAndDown"
      height="32px"
    >
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="mx-1"
        small
      />
    </v-app-bar> -->
    <system-message />

    <v-main>
      <router-view>
      </router-view>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from './components/Navigation/Index'
import SystemMessage from './components/Common/SystemMessage'
import { mapActions } from 'vuex'

export default {
  components: {
    Navigation,
    SystemMessage
  },

  data: () => ({ drawer: true }),

  mounted () {
    this.subscribe()
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
      subscribe: 'devices/subscribe'
    }),
    setDrawer (payload) {
      this.drawer = payload
    }
  },

  computed: {
    hideNav () {
      return this.$route.meta.hideNav
    }
  }
}
</script>
