import { Style, Stroke, Fill, Circle as CircleStyle, Icon, Text } from 'ol/style.js'

import store from '@/store/index'
const clusterStyle = (feature) => {
  feature.setStyle((feature) => {
    const zoom = store.getters['gis/map'].getView().getZoom()
    const radius = zoom < 10 ? 8 : zoom * 1.2
    const scale = zoom < 10 ? 0.22 : zoom / 35

    const features = feature.get('features')
    const num = features.length

    const selected = store.getters['gis/selected']
    const selectedFeatures = selected.length ? selected[0].get('features') : []

    const numSelected = features.filter(val => {
      return selectedFeatures && selectedFeatures.includes(val)
    }).length

    const img = (num === 1) ? (features[0].get('model').icon || null) : null

    const style = [
      new Style({
        image: new CircleStyle({
          stroke: new Stroke({
            color: '#0097A7',
            width: 1.5
          }),
          fill: new Fill({
            color: 'white'
          }),
          radius: radius
        })
      }),
      new Style({
        text: !img
          ? new Text({
            text: num.toString(),
            font: 'bold ' + (radius + 2) + 'px sans-serif',
            fill: new Fill({
              color: '#333'
            })
          })
          : null
      }),
      new Style({
        image: img
          ? new Icon({
            anchor: [0.5, 0.5],
            scale: scale,
            src: img
          })
          : null
      })
    ]

    if (numSelected) { // selection style
      style.push(
        new Style({
          image: new CircleStyle({
            stroke: new Stroke({
              color: 'white',
              width: 5
            }),
            radius: radius
          })
        }),
        new Style({
          image: new CircleStyle({
            stroke: new Stroke({
              color: '#00B8D4',
              width: 2,
              lineDash: (num !== numSelected) ? [2, 1] : null
            }),
            radius: radius
          })
        })
      )
    }

    return style
  })
}

export {
  clusterStyle
}
