import axios from 'axios'

const auth = (context, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('/api/auth/', payload.params)
      .then((response) => {
        context.commit('AUTH', response.data)
        console.log(response.data)
        resolve(response.data)
      })
      .catch((e) => { reject(e) })
  })
}

const logout = (context) => {
  return new Promise((resolve, reject) => {
    axios.post('/api/logout/')
      .then((response) => {
        context.commit('AUTH', null)
        window.location.href = '/'
        resolve(response.data)
      })
      .catch((e) => { reject(e) })
  })
}

const getUser = (context) => {
  return new Promise((resolve, reject) => {
    axios.get('/api/user/')
      .then((response) => {
        context.commit('AUTH', response.data)
        resolve(response.data)
      })
      .catch((e) => {
        context.commit('AUTH', {})
        reject(e)
      })
  })
}

export default {
  auth,
  logout,
  getUser
}
